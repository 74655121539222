@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');



.who{
  position: relative;
  width:62px;
  margin-bottom: -5px;
  cursor: pointer;
  opacity: 1;

}

.bg{
  position: relative;
  height:100%;
    font-size: 16px;



}
/* ShowPersona.module.css */
.bg {
  position: relative; /* Make sure your .bg div is positioned */
}

.hoverInfo {
  position: absolute;
  left: 0;

  display: none; /* Hide by default */
  background-color: rgba(250,250,0,.9); /* Example background */
  padding: 10px;
  width:220px;
  height:160px;
  color:black;
/* Example styling, customize as needed */
  z-index: 1300;
  pointer-events: none;
}

.who:hover + .hoverInfo, /* When hovering the image, display the div next to it */
.hoverInfo:hover { /* Keep the div displayed when hovering over it */
  display: block;
}

.personablurb{
  position: absolute;
  margin-top: 5px;
  text-align: left;
  font-weight: bold;
  font-size:14px;
}
