:root {
    --scrollbar-track-color: #f1f1f1;
    --scrollbar-thumb-color: rgba(0,0,0,1);
    --scrollbar-bg-color: rgba(10,10,255,0);
    --scrollbar-height: 400px;
    --placeholdercolor: rgba(50,50,50,1);
}

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap')




.ubuntu-light {
  font-family: "Ubuntu", sans-serif;
  font-weight: 300;
  font-style: normal;
}


.container {
  display: flex;
  flex-direction: column; /* Align children vertically */





  background-color: white;
  flex-wrap: wrap; /* Allow wrapping */

  border-radius: 1px;
  color: red;
  flex-grow: 1;
  box-sizing: border-box; /* Ensures padding/border is included in the element's size */

 flex-grow: 1 !important;
 position: relative;
 overflow: hidden;






}

/* Apply box-sizing: border-box to all child elements of the container */
.container > * {
  position: relative;
  box-sizing: border-box;
}

.leftColumn {
  width: 270px; /* Adjusted width to 150px as per requirements */
  padding: 0px 5px;
  margin-left: 10px;


}
/*
.rightColumnResult {
    display: flex;
    position: relative;

    flex-direction: column;
    flex-grow: 1;
    width: 300px;
    padding: 10px;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items:flex-start;
    text-align: left;
    font-size: 14px;

    min-height: 150px;



}
*/
.tts{
  margin-left: auto;



margin-right: 0px;
  z-index: 1000;

}

.ttsFull{
  position:relative;
  margin-left: auto;

  z-index: 1000;

  width:100%;
  bottom:0;
  font-size:14px;


}
.tts2{
  position: relative;
}
.postit{
  cursor: pointer;
}

.sponsor{

 z-index: 1000;

}

.sponsor img{


  margin-top:-15px;
  height:100px;
}
.sponsorBig{
  position: absolute;
  top:10px;
  left:10px;

  z-index: 3000;
}

.sponsorBig img{
    height:100px;
}


textarea::placeholder {
    color: var(--placeholdercolor) !important; /* Change #909090 to any color you wish */
}
.rightColumn {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-grow: 1;
  width: 330px;



    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    height:300px;




}

.rightColumnChat {
  display: flex;
    position: relative;
    flex-direction: column;
    flex-grow: 1;
    width: 330px;
    height: 330px !important; /* Consider removing !important if not necessary */

    box-sizing: border-box;
    justify-content: flex-start; /* Changed from center to flex-start center */
    align-items: stretch; /* Changed from center to stretch to use the full width center*/
    overflow-y: auto; /* Added this to handle scrolling */
    overflow-x:hidden;
    font-size: 16px;

    padding:20px;
    text-align: left;

    border-width: 15px 0 15px 0px;  /* Top, Right, Bottom, Left */
    border-style: solid;          /* Same style for all specified borders */
    border-color: rgba(0,0,0,0);           /* Same color for all specified borders */
    border-right-style: none;




}
.rightColumnChatCenter {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-grow: 1;
    width: 330px;
    height: 330px;

    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    padding: 20px;
   text-align: left;
}


.rightColumnResult {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-grow: 1;
  width: 300px;



    box-sizing: border-box;
    justify-content: center;
    align-items: left;
    font-size: 12px;
    text-align: left;

    min-height: 180px;


    height:300px;
    padding:0px;

    border:1px solid red;
    height:1000px;


}



.row {
  display: flex;
   flex-direction: column;
  justify-content: flex-start !important; /* Align items to the start of the row */
  align-items: start;




}

.columnbasic {
  display: flex;
  flex-direction: row; /* Align items horizontally */
  justify-content: space-between; /* Distribute space between children */
  align-items: center; /* Vertically center align the children */

}

.rightColumn2 {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
  width: 325px;
  height:80px;


  margin-top:-10px;

}

.rowtop{
  display: flex;
  justify-content: flex-end !important; /* Align items to the start of the row */
  align-items: start;
  padding:10px 25px 0px 10px;

}


.rowcenter {
  display: flex;
  justify-content: center; /* Align items to the start of the row */
  align-items: center;
  padding:10px;



}

.rowright {
  display: flex;
  flex-direction: row; /* Correct this for horizontal layout */
  justify-content: center;
  align-items: center;
  height:20px;

}
.rowsponsor{
  width:200px;
}

.resulttext{
  font-size: 20px;
  padding-bottom: 10px;
  font-weight:bold;
}

.bglayer{
  position: absolute;

  box-sizing: border-box;
  height:100%;
  width:100%;

}
.bglayerchat{
  position: absolute;
  z-index: 0;

  box-sizing: border-box;
  height:100%;
  width:100%;


}
.chatbox{
  position: relative;
  border:2px solid red;

}
.chat{
  font-size: 17px;
  z-index: 100;
  padding:14px;
  line-height: 1.2;




}
.firstClickChat{
  text-align: center;
  padding:0 100px;

}
.scrollableDiv {
  height:var(--scrollbar-height) !important;
  overflow-y: auto;
}

.scrollableDiv::-webkit-scrollbar {
    width: 12px;  /* You can adjust the width as needed */
}


.scrollableDiv::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
}

/* Styles the handle (thumb) of the scrollbar */
.scrollableDiv::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color);
    border-radius: 6px;  /* Optional: Adds rounded corners to the thumb */
    border: 3px solid rgba(0,0,0,0);  /* Optional: Adds a border around the thumb */
}
.scrollableDiv::-webkit-scrollbar-track {
    background: var(--scrollbar-bg-color);  /* Set track background to blue */
}


.pages{
  position: absolute;
  bottom: -16px;
  right:10px;
  font-size: 12px;
  color:black;
  color:white;
  z-index:2000;
  cursor: pointer;
}

.personaui{
  margin-top: 0px;
  z-index:1000;
  margin-left: 10px;
}
.textbox{
  position: relative;
  margin-right:8px;
  width:300px;
  height:40px;

  resize: none;
  padding:12px;
  border-radius: 10px;
  z-index: 102;
  font-weight: bold;
  overflow: hidden;
  font-size: 14px;
}
.headbg{
  position: absolute;
  width:100%;
  height: 100%;
  z-index: 0;
}

.footbg{
  position: absolute;
  width:100%;
  height: 180px;
  z-index: 1;

  bottom: 0;
}

.title {
  font-size: 70px;
  font-weight: bold;
  padding: 5px;

  font-family: "Lato", sans-serif;
  font-weight: 700;
  width: 100%;
  margin-top: 20px;
  z-index:10;





}

.subtitle {
  font-size:30px;
  font-weight: bold;
  padding: 5px;

  font-family: "Lato", sans-serif;

  font-weight: 400;
  font-style: italic;
  width: 100%;
    line-height: .2;
    margin-bottom: 20px;

    box-sizing: border-box;
    text-shadow: 0 0 5px rgba(250,150,110, 1);
    z-index:11;
}
.capture{
  font-size: 18px;
  margin-left: 5px;
  margin-bottom: 10px;

}
.cta{
  margin-left: 5px;
  font-size: 14px;

}
.insight{

  margin-top:15px;
  padding: 10px;
  font-size: 10px;

}
#result{
  font-size: 20px !important;
}

.topicinput {
  width: 100% !important; /* or any other width as per design requirements */
  margin: 0px 10px !important;

  font-size: 12px;
    outline: none;
    border-radius: 3px;
    border:0px solid white;


}

.topicinput:focus {
  border-color: #FFF; /* Custom focus style */

  box-shadow: 0 0 0px #FFF; /* Optional: add a subtle shadow as a focus indicator */
}
.ring {
  position: absolute;
  width: 30%;
  opacity: .3; /* This initial opacity will be overridden by the animation */
  animation: pingPong 4s infinite; /* No quotes around pingPong */
}

@keyframes pingPong {
  0%, 100% {
    transform: scale(0.1);
    opacity: .1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.5;
  }
}
input:focus {

}
.center_container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width:95%;



  font-family: Arial, sans-serif;




  padding:20px;
}

.jokeHolder{
   flex-direction: column;
   position: relative;


   border-radius: 5px;

   padding-bottom: 10px;
   border:1px solid yellow;


}
.jokeContainer {
  position: relative;
  width:40vw;
  height:100px;




  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  padding: 60px 30px 0 30px;
  font-size: 18px;

  box-sizing: border-box;

   border:1px solid white;


}


.topicDiv {
  border-radius: 3px;


}

#topicDiv:focus {
border-radius: 3px;
  border-color: #000; /* Highlight the border to indicate focus */
  /* If you don't want any border change on focus, keep the border consistent */
  outline: none; /* Ensures no outline appears on focus */
}

button_6 {
  position: relative;
  margin-top: 0px;
  z-index: 100;
}

.button_6 {
  position: relative;
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;

  cursor: pointer;
  display: inline-flex;
  font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 1rem;
  padding: calc(.2rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
  margin-top:0px;
}

.button_6:disabled {
  opacity: 0.5;
  cursor: default; /* Change cursor to default */
  box-shadow: none; /* Remove box-shadow if any */
}

/* Disable hover and focus styles for disabled button */
.button_6:disabled:hover,
.button_6:disabled:focus {
  border-color: rgba(0, 0, 0, 0.1); /* Reset to default or remove changes */
  box-shadow: none;
  transform: none;
  color: rgba(0, 0, 0, 0.85); /* Reset color or adjust as needed */
}

.button_6:hover,
.button_6:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;

}

.button_6:hover {
  transform: translateY(-1px);
}

.button_6:active {
  background-color: #F0F0F1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

/*dropzone*/

.dropzoneHolder{
  position: relative;


  font-size: 18px;
  text-align: center;
    font-family: 'Encode Sans Condensed', sans-serif;
   white-space:pre-line;
}
.dropZone {
  position: relative;
  border-radius: 3px;
  width:150px;
  height: 150px;
  color:transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
    background-color: rgba(255, 255, 255, 1);
    margin-bottom: 10px;
    left:5px;

}


::-webkit-file-upload-button {
   display: none;
}

::file-selector-button {
  display: none;
}

input#file {
  display: inline-block;
  width: 100%;
  padding: 120px 0 0 0;
  height: 100px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  border-radius: 20px;
  background-size: 60px 60px;
}

.inputImg{
  max-width:100%;
}

.lato-thin {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.lato-thin-italic {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.lato-light {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.lato-light-italic {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.lato-regular {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lato-regular-italic {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.lato-bold {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.lato-bold-italic {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.lato-black {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.lato-black-italic {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: italic;
}
.ubuntu-regular {
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.ubuntu-medium {
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.ubuntu-bold {
  font-family: "Ubuntu", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.ubuntu-light-italic {
  font-family: "Ubuntu", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.ubuntu-regular-italic {
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.ubuntu-medium-italic {
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.ubuntu-bold-italic {
  font-family: "Ubuntu", sans-serif;
  font-weight: 700;
  font-style: italic;
}
