.App {
  background-color: #000;
  text-align: center;

  background-size: cover;
  margin: 0;
  padding: 0;
  overflow-y:hidden;
  overflow-x: hidden;
  font-family: "helvetica neue", Arial, sans-serif;
}







@media (max-width: 3000px) {
  .App-row  {
    flex-direction: column; /* Stack columns vertically on smaller screens */
  }
}
