.page {
  display: flex;
  flex-direction: column;  /* Stacks children vertically */
  height: 100vh;           /* Full height of the viewport */
  width: 100vw;            /* Full width to avoid horizontal scrollbar */
  margin: 0;               /* Removes default margin */
  font-family: "helvetica neue", Arial;
}


.container {
  display: flex;           /* Establishes a flex container */
  flex-grow: 1;            /* Allows the container to fill available space */
  background-color: black; /* Background color for the entire row */
}

.column {
  display: flex;               /* Allows content alignment using flex properties */
  align-items: center;         /* Vertically centers content in all columns by default */
  justify-content: center;     /* Horizontally centers content */
  color: white;                /* Text color for visibility against the black background */
  border-right: 1px solid gray; /* Gray stroke on the right of each column */
}

.column-1, .column-4 {
  width: 50px;                /* Fixed width as specified */
}

.column-2 {
  width: 300px;
  align-items: flex-start;    /* Aligns content to the top */
}

.column-3 {
  flex-grow: 1;               /* Takes up remaining space */
  min-width: 150px;           /* Minimum width */
  display: flex;              /* Establishes a flex container */
  flex-direction: column;     /* Stacks children vertically */
  align-items: center;        /* Centers content horizontally */
  justify-content: flex-end;  /* Aligns content to the bottom */
  border-right: none;         /* No border on the right */
  min-width: 400px;
  overflow: hidden !important;

   position: relative;
   height:95vh;



}

.column-3 > div {
  width: 100%;                /* Each child div takes the full width of the column */
  display: flex;              /* Makes each child div a flex container */
  justify-content: center;    /* Horizontally centers content within each child div */
  align-items: center;        /* Vertically centers content within each child div */
}

.orange{

  color:#ff7e16;



}
.headline {
  position: relative;
  display: block !important;
  font-size: 70px;
  max-width: 600px;
  text-align: left;
  font-weight: 800;
  line-height: 1;
  margin-bottom: 100px;



}

.spacer {
  height: 50px;               /* Arbitrary height for the spacer */
}

.column-4 {
  border-right: none;
  border-left: 1px solid gray; /* Added left border for visual symmetry */
}

.footer {
  height: 70px;               /* Fixed height for footer */
  background-color: #ff7e16;  /* Hexadecimal color for footer background */
  display: flex;
  align-items: center;        /* Centers content vertically */
  justify-content: center;    /* Centers content horizontally */
  color: white;               /* Text color */
}

@media (max-width: 600px) {
  .column-2 {
    display:none;
  }
}
