.toolbar{
  position: absolute;
  left:0;
  display: flex;
   flex-direction: column;
  justify-content: center;
  align-items: center;
  width:100%; /*ws 320px */
  height:20px;




}

.button{
  cursor: pointer;
  width:100px;
  height:20px;
  padding:10px;
  margin-left:-20px;
}

.download{
  position:absolute;
  left:20px;
}
.downloadright{
  right:20px;
  left:auto;
}
.ui_download{
  width:20px;
  cursor: pointer;
  padding-left: 12px;
  padding-bottom: 2px;

}
